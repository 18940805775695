@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url(./assets/fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}
