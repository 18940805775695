.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  margin: 0;
}

.leaflet-container {
  height: 100vh;
  font-family: 'Montserrat', sans-serif;
}

.station-dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  border: 3px solid;
  background-color: #fff;
}

.terminal-station-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: 4px solid;
  background-color: #fff;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
  outline: none !important;
}

.custom-autocomplete .MuiInputBase-input {
  margin-left: 42px !important;
}

.custom-autocomplete .MuiAutocomplete-endAdornment {
  pointer-events: all;
}

.polyline-arrow {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  cursor: default;
}
